import PropTypes from "prop-types";
import React from "react";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "app/pages/.shared/Page/page.scss";

const Page = ({ title, children }) => {
	return (
		<div className="page">
			<header className="page__title">{title}</header>
			<section className="page__content">{children}</section>
		</div>
	);
};

Page.propTypes = {
	title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default registerStyle(style)(Page);

import React from "react";
import classNames from "classnames";
import { PropTypes } from "prop-types";
import { FormattedDate, FormattedMessage } from "react-intl";
import IconDeposit from "app/pages/.shared/static/icons/IconDeposit";
import AmountContainer from "app/pages/.shared/AmountContainer.jsx";
import { DEPOSIT_PERCENTAGE, DEPOSIT_PERCENTAGE_DE } from "app/constants";

import "./PayNow.scss";

const PayNow = ({
	isPaymentPage,
	total,
	mensualite2Fois,
	withDepositIcon,
	withSuffix,
	isPaymentPriceDetails = false,
	shop,
}) => {
	const [lang] = shop.split("-");
	const isLangFr = lang === "fr";
	const isShopDe = shop === "de-DE";

	const percentage = isShopDe ? DEPOSIT_PERCENTAGE_DE : DEPOSIT_PERCENTAGE;

	const payNow = mensualite2Fois
		? mensualite2Fois.payNowAmount
		: total && Math.ceil((total * percentage) / 100);

	const labelPaymentKey = isLangFr
		? "my.bookings.deposit.pay.now.label.2"
		: "my.bookings.deposit.pay.now.label";

	const optionalDepositKey =
		isLangFr || payNow
			? "my.bookings.deposit.optionel.label.2" // label affiché quand montant disponible
			: "my.bookings.deposit.optionel.label"; // label affiché quand montant non disponible

	return (
		<div className="pay-now">
			<div className="pay-now__label-container">
				<div className="pay-now__logo-container">
					{withDepositIcon && <IconDeposit width={20} height={27} />}
					<div className="pay-now__label">
						<FormattedMessage
							id={isPaymentPage ? labelPaymentKey : optionalDepositKey}
						/>
					</div>
				</div>

				{payNow && (
					<div
						className={classNames("pay-now__value", {
							"pay-now__value--payment": isPaymentPage,
						})}
					>
						<AmountContainer
							amount={payNow}
							suffix={
								withSuffix ? (
									<FormattedMessage id="general.amount.per.person.suffix.label.short" />
								) : (
									""
								)
							}
						/>
					</div>
				)}
			</div>

			<div className="pay-now__note">
				{isPaymentPage ? (
					<FormattedMessage
						id={
							isPaymentPriceDetails
								? "my.bookings.pay.now.note"
								: "payment.methods.partial.label.simple.2"
						}
						values={
							isPaymentPriceDetails
								? { percent: `${percentage}%` }
								: {
										solde: (
											<AmountContainer
												amount={mensualite2Fois.payLaterAmount}
											/>
										),
										date: (
											<span className="pay-now__note__date">
												<FormattedDate
													timeZone="UTC"
													day="2-digit"
													month="2-digit"
													year="numeric"
													value={mensualite2Fois.dueDate}
												/>
											</span>
										),
								  }
						}
					/>
				) : (
					<FormattedMessage
						id="my.bookings.pay.now.note"
						values={{
							percent: `${percentage}%`,
						}}
					/>
				)}
			</div>
		</div>
	);
};

PayNow.propTypes = {
	total: PropTypes.number,
	mensualite2Fois: PropTypes.object,
	withDepositIcon: PropTypes.bool,
	withSuffix: PropTypes.bool,
	isPaymentPage: PropTypes.bool,
	isPaymentPriceDetails: PropTypes.bool,
	shop: PropTypes.string,
};

export default PayNow;

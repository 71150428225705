import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import List from "app/pages/.shared/List.jsx";
import "./NegosList.scss";

const NegosList = ({ hotelNegos, flightNegos, showOfferType, offerTypeLabel }) => {
	const prefixedFlightNegos = flightNegos.map(nego => {
		return showOfferType ? `${nego} (${offerTypeLabel})` : nego;
	});

	return (
		<div className="nego">
			<div className="nego__headline">
				<FormattedMessage id="search.engine.negociated.headline" />
			</div>

			<List items={hotelNegos} enableHTMLFormatting={true} />

			<List items={prefixedFlightNegos} enableHTMLFormatting={true} />
		</div>
	);
};

NegosList.propTypes = {
	hotelNegos: PropTypes.arrayOf(PropTypes.string),
	flightNegos: PropTypes.arrayOf(PropTypes.string),
	showOfferType: PropTypes.bool,
	offerTypeLabel: PropTypes.string,
};

NegosList.defaultProps = {
	hotelNegos: [],
	flightNegos: [],
	showOfferType: false,
};

export default React.memo(NegosList);

import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg.jsx";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./patchwork.scss";

const PHOTO_SIZES = [
	{ width: 296, height: 268 },
	{ width: 296, height: 268 },
	{ width: 196, height: 145 },
	{ width: 196, height: 145 },
	{ width: 196, height: 145 },
];

class Patchwork extends React.PureComponent {
	render() {
		const { photos = [], onShowMore } = this.props;

		const photosNodes = photos.slice(0, 5).map((photo, index) => {
			const isLastItem = index === 4;

			const imgSizes = {
				large: PHOTO_SIZES[index],
				xlarge: PHOTO_SIZES[index],
			};

			return (
				<div key={index} className="patchwork__item">
					<ProgressiveImg
						sizes={imgSizes}
						src={photo.url}
						thumbnail={photo.thumbnail}
						alt={photo.alt}
					/>
					{isLastItem && (
						<div className="patchwork__more">
							<i className="icon icon--loupe-white" />
							<FormattedMessage id="general.patchwork.see.more.label" />
						</div>
					)}
				</div>
			);
		});

		return (
			<div className="patchwork" onClick={onShowMore}>
				{photosNodes}
			</div>
		);
	}
}

Patchwork.propTypes = {
	photos: PropTypes.array,
	onShowMore: PropTypes.func,
};

export default registerStyle(style)(Patchwork);

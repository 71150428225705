import PropTypes from "prop-types";
import React from "react";
import { reduxFormDomOnlyProps } from "app/utils/form/formUtils";
import "./NumberInput.scss";

class NumberInput extends React.Component {
	constructor() {
		super();
		this.decrement = this.decrement.bind(this);
		this.increment = this.increment.bind(this);
	}

	decrement() {
		const newValue = Number(this.props.field.value) - 1;

		if (
			((typeof this.props.canDecrement === "function" && this.props.canDecrement(newValue)) ||
				this.props.canDecrement === undefined) &&
			newValue >= 0
		) {
			this.props.field.onChange(newValue);

			if (typeof this.props.onValueChanged === "function") {
				this.props.onValueChanged(newValue);
			}
		}
	}

	increment() {
		const newValue = Number(this.props.field.value) + 1;

		if (
			(typeof this.props.canIncrement === "function" && this.props.canIncrement(newValue)) ||
			this.props.canIncrement === undefined
		) {
			this.props.field.onChange(newValue);

			if (typeof this.props.onValueChanged === "function") {
				this.props.onValueChanged(newValue);
			}
		}
	}

	render() {
		const { id, field } = this.props;

		return (
			<div className="number-input">
				<button
					type="button"
					className="number-input__button"
					onClick={this.decrement}
					data-cy={`number-input-${id}-decrement`}
				>
					-
				</button>
				<input
					{...reduxFormDomOnlyProps(field)}
					disabled
					className="number-input__input"
					id={id}
					type="number"
				/>
				<button
					type="button"
					className="number-input__button"
					onClick={this.increment}
					data-cy={`number-input-${id}-increment`}
				>
					+
				</button>
			</div>
		);
	}
}

NumberInput.defaultProps = {
	field: {},
};

NumberInput.propTypes = {
	field: PropTypes.object,
	id: PropTypes.string.isRequired,
	onValueChanged: PropTypes.func,
	canIncrement: PropTypes.func,
	canDecrement: PropTypes.func,
};

export default NumberInput;

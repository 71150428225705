import { connect } from "react-redux";
import { getQuoteTotalAvecFrais } from "app/pages/Booking/bookingSelectors";

import PayNow from "app/pages/.shared/PayNow/PayNow";

const mapStateToProps = state => {
	return {
		total: getQuoteTotalAvecFrais(state),
		paymentType: state.booking.paymentType,
		paymentMode: state.booking.paymentMode,
		shop: state.shop,
	};
};

export default connect(mapStateToProps)(PayNow);

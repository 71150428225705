import React from "react";
import Button from "app/pages/.shared/form/Button";
import { anyFieldTouched } from "app/utils/form-validator";
import Popover from "app/pages/.shared/Popover";
import { NEWSLETTER_FREQUENCIES } from "app/constants";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { propTypes } from "redux-form";
import "./NewsLetterFrequency.scss";
import Slider from "rc-slider";
import Switch from "app/pages/.shared/form/Switch";
import Separator from "app/pages/.shared/Separator";
import get from "lodash/get";

const MARKS = Object.keys(NEWSLETTER_FREQUENCIES).reduce((obj, freqKey, key) => {
	let label;
	if (NEWSLETTER_FREQUENCIES[freqKey] === NEWSLETTER_FREQUENCIES.ALL) {
		label = (
			<div className="newsletter__label">
				<div className="newsletter__label--strong">
					<FormattedMessage id="newsletter.all.label.1" />
				</div>
				<FormattedMessage id="newsletter.all.label.2" />
			</div>
		);
	} else if (NEWSLETTER_FREQUENCIES[freqKey] === NEWSLETTER_FREQUENCIES.WEEKLY) {
		label = (
			<div className="newsletter__label">
				<div className="newsletter__label--strong">
					<FormattedMessage id="newsletter.weekly.label.1" />
				</div>
				<FormattedMessage id="newsletter.weekly.label.2" />
			</div>
		);
	} else if (NEWSLETTER_FREQUENCIES[freqKey] === NEWSLETTER_FREQUENCIES.NONE) {
		label = (
			<div className="newsletter__label">
				<div className="newsletter__label--strong">
					<FormattedMessage id="newsletter.none.label.1" />
				</div>
				<FormattedMessage id="newsletter.none.label.2" />
			</div>
		);
	}
	return { ...obj, [key]: { label: label, value: NEWSLETTER_FREQUENCIES[freqKey] } };
}, {});

const NewsLetterFrequencyComponent = ({
	handleSubmit,
	fields,
	submitSucceeded,
	error,
	intl,
	submitting,
	invalid,
	submitFailed,
}) => {
	const errorMessage = error ? intl.formatMessage(error) : "";

	const frequency = get(fields, "newsletterPreference.frequency", {});
	const marketing = get(fields, "newsletterPreference.remarketing", {});

	const defaultValue = Object.keys(MARKS).find(key => MARKS[key].value === frequency.value);

	return (
		<form className="newsletter__form" onSubmit={handleSubmit}>
			<div className="newsletter__frequency">
				<div className="newsletter__title">
					<FormattedMessage id="subscriptions.intro" />
				</div>

				{frequency.value !== "" && (
					<div className="newsletter__content">
						<Slider
							marks={MARKS}
							onChange={value => frequency.onChange(MARKS[value].value)}
							max={Object.keys(NEWSLETTER_FREQUENCIES).length - 1}
							defaultValue={defaultValue}
						/>
					</div>
				)}
			</div>

			{get(fields, "newsletterPreference.remarketing.value") !== "" && (
				<React.Fragment>
					<Separator design="middle" />

					<div className="newsletter__remarketing">
						<div className="newsletter__title">
							<FormattedMessage id="subscriptions.remarketing.intro" />
						</div>

						<div className="newsletter__content">
							<Switch
								id="newsletter-remarketing-button"
								name="newsletter-remarketing-button"
								field={marketing}
								data-cy="remarketing-input"
							>
								<FormattedMessage id="subscriptions.remarketing.label.title">
									{title => (
										<div className="newsletter__label--strong">{title}</div>
									)}
								</FormattedMessage>
								<FormattedMessage id="subscriptions.remarketing.label.content">
									{content => <div className="newsletter__label">{content}</div>}
								</FormattedMessage>
							</Switch>
						</div>
					</div>
				</React.Fragment>
			)}

			<div className="newsletter__action">
				<Button
					loading={submitting}
					submit
					design="primary"
					data-cy="update-frenquency-button"
				>
					{submitSucceeded ? <i className="icon icon--tick" /> : false}

					{submitSucceeded ? (
						<FormattedMessage id="general.success.button.label" />
					) : (
						<FormattedMessage id="subscriptions.button.label" />
					)}
				</Button>
				<Popover isOpen={((anyFieldTouched(fields) && invalid) || submitFailed) && error}>
					{/* TODO passer a la version > 0.4.5 dereact-popover pour pouvoir utiliser un FormattedMessage au lieu de intl  */}
					{errorMessage}
				</Popover>
			</div>
		</form>
	);
};

NewsLetterFrequencyComponent.propTypes = {
	...propTypes,
	intl: intlShape,
};

export const NewsLetterFrequency = injectIntl(NewsLetterFrequencyComponent);
